export const STUDENT = 'manageStudentSlice'
export const STUDENT_API_URL = 'students'
export const GET_STUDENT_PERSONAL_INFO = 'get-student-personal-info'
export const ADD_STUDENT_PERSONAL_INFO = 'add-student-personal-info'
export const DELETE_STUDENT_PICTURE = 'delete-student-picture'
export const GET_STUDENT_MEDICAL_INFO = 'get-student-medical-info'
export const ADD_STUDENT_MEDICAL_INFO = 'add-student-medical-info'
export const GET_STUDENT_GUARDIAN = 'get-student-guardians'
export const ADD_STUDENT_GUARDIAN = 'add-student-guardian'
export const DELETE_STUDENT_GUARDIAN = 'delete-student-guardian'
export const GET_STUDENT_SIBLINGS = 'get-student-siblings'
export const GET_GUARDIAN_CHILDRENS = 'get-guardian-childrens'
export const ADD_STUDENT_SIBILING = 'add-student-sibiling'
export const DELETE_STUDENT_SIBILING = 'delete-student-sibling'
export const GET_STUDENT_ENROLLMENTS = 'get-student-enrollments'
export const ADD_STUDENT_ENROLLMENTS = 'add-student-enrollment'
export const DELETE_STUDENT_ENROLLMENT = 'delete-student-enrollment'
export const GET_STUDENT_DOCUMENTS = 'get-student-documents'
export const GET_ALL_DOCUMENT_TYPES = 'get-all-document-types'
export const ADD_STUDENT_DOCUMENT = 'add-student-document'
export const DELETE_STUDENT_DOCUMENT = 'delete-student-document'
export const GET_STUDENT_ACTIVE_ENROLLMENTS = 'get-student-active-enrollment'
export const GET_GRADE_CHARGES = 'get-grade-charges'
export const ADD_STUDENT_CHARGES = 'add-student-charges'
export const SEARCH_STUDENTS = 'search-students'
export const GET_ALL_STUDENTS_DROPDOWN_URL = 'get-all-students-dropdown'
export const PRE_LOAD_PROMOTE_STUDENT = 'pre-load-promote-student'
export const PRE_LOAD_GRADES = 'pre-load-grades'
export const FETCH_GRADE_STUDENTS = 'fetch-grade-students'
export const SAVE_PROMOTED_STUDENTS = 'save-promoted-students'

